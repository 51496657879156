import * as api from "../../utils/api";
import PhotolabTaskBuilder from "../PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../PhotolabTaskImageUrl";
import Creative from "../Creative";
import {
  CreativeTimeoutError,
  creativeTimeoutPromise,
  defaultHandlerCatch,
  defaultHandlerResolver, imageToCanvas,
  promisifyImage,
  waitTaskHelper
} from "./helpers";
import {photolabTask} from "../api";
import {createMd5Token, hashCode, upperCaseFirstChar} from "../../utils/text";
import {assetUrl} from "../../utils/etc";
import {transformToDownloadUrl} from "../../utils/creative";
import {hitEvent, hits, logEvent, userEvents} from "../../utils/log";
import Processing from "../Processing";
import {
  drawDebugRect,
  drawTextLines,
  textSpansToLines,
  textToSpans
} from "./texts";

const textSign = "My Halloween art, October '22";

const tasksCache = {};

const fontsLibrary = {
  MyriadProRegular: {
    name: "Myriad Pro",
    style: "normal",
    weight: "400",
    url: `url(${assetUrl("assets/fonts/myriad_pro/MyriadPro-Regular.woff")})`,
  },
  MyriadProSemibold: {
    name: "Myriad Pro",
    style: "normal",
    weight: "600",
    url: `url(${assetUrl("assets/fonts/myriad_pro/MyriadPro-Semibold.woff")})`,
  },
  PoppinsBold: {
    name: "Poppins",
    style: "normal",
    weight: "700",
    url: `url(${assetUrl("assets/fonts/poppins/Poppins-Bold.ttf")})`,
  },
  AvenirNextCyrDemi: {
    name: "AvenirNextCyrDemi",
    style: "normal",
    weight: "700",
    url: `url(${assetUrl("assets/fonts/avenir_next_cyr_demi/avenirnextcyr-demi.ttf")})`,
  },
};

function getResultUrlFromTask(task) {
  if (task.requestId) {
    return task.resultUrl
  } else {
    return task.result.url;
  }
}

function templateStep(processing, creative, stepIndex, stepConfig) {
  if (!stepConfig.id) {
    return Promise.reject("No template ID parameter.");
  }

  const taskConfigBuilder = new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod(stepConfig.id));

  // @ - original photo
  // # - prev step result
  // #N - N step result, todo
  (stepConfig.images || [{src: "#"}]).forEach((image) => {
    const source = image.src || "#";
    let imageUrl;

    if (source === "@") {
      imageUrl = processing.file.url;
    } else if (source === "@layoutFile") {
      imageUrl = processing.layoutFile.url;
    } else if (source === "@selfieMessageFile") {
      imageUrl = processing.getExtra(Processing.EXTRA_SELFIE_MESSAGE_FILE).url;
    } else if (source === "#") {
      if (stepIndex === 0) {
        imageUrl = processing.file.url;
      } else {
        imageUrl = getResultUrlFromTask(creative.getTask("s" + (stepIndex-1)));
      }
    } else if (/^#\d+$/.test(source)) {
      imageUrl = getResultUrlFromTask(creative.getTask("s" + source.substring(1)));
    } else {
      imageUrl = source;
    }

    const altBody = (image.useAltBody && processing.maskFile) ? processing.maskFile.url : "";
    const fileName = altBody.split("/").pop();
    const hash = fileName.substring(0, fileName.lastIndexOf("."));

    taskConfigBuilder.addImage(new PhotolabTaskImageUrl(
      imageUrl + (hash.length ? "?" + hash : ""),
      "",
      0,
      0,
      altBody
    ));
  });

  const taskConfig = taskConfigBuilder.build()
  const taskCacheKey = createMd5Token(taskConfig);

  if (!tasksCache[taskCacheKey]) {
    tasksCache[taskCacheKey] = photolabTask(
      taskConfig,
      stepConfig.getResultTimeout || 1000,
      stepConfig.getResultInterval || 1000,
    )
  }

  creative.setTaskConfig("s" + stepIndex, taskConfig);

  return tasksCache[taskCacheKey].then((taskResult) => {
    creative.setTask("s" + stepIndex, taskResult);

    if (typeof stepConfig.setAsFile === "string" && stepConfig.setAsFile.length > 0) {
      creative.setFile(stepConfig.setAsFile, taskResult.resultUrl);
    }

    return taskResult;
  }).catch((err) => {
    if (err.name === "PhotolabResponseError" && err.code === -1028) {
      if (stepConfig.skipOnMultifaceError) {
        // const steps = creative.getExtra(Creative.EXTRA_COMBO_STEPS);
        // steps.splice(stepIndex, 1);
        // const name = steps.map((step) => step.id).join("_");
        //
        // creative.setExtra(Creative.EXTRA_NAME, name);

        const imageUrl = stepIndex === 0
          ? processing.file.url
          : getResultUrlFromTask(creative.getTask("s" + (stepIndex-1)));

        const result = {
          skipped: "skipOnMultifaceError",
          result: {url: imageUrl}
        };

        creative.setTask("s" + stepIndex, result);
        return result;
      }

      if (stepConfig.fallbackId) {
        const newStepConfig = JSON.parse(JSON.stringify(stepConfig));
        newStepConfig.id = newStepConfig.fallbackId;
        delete newStepConfig.fallbackId;
        return templateStep(processing, creative, stepIndex, newStepConfig);
      }
    }

    delete tasksCache[taskCacheKey];
    throw err;
  });
}

export function sdStep(processing, creative, stepIndex, stepConfig) {
  if (!stepConfig.seed) {
    return Promise.reject("No template SEED parameter.");
  }

  const userText = processing.getExtra(Processing.EXTRA_TEXT, "");
  const userTextSeed = Math.abs(hashCode(userText)) % 500;
  const resultSeed = stepConfig.seed + userTextSeed;

  creative.setExtra(Creative.EXTRA_AI_SEED, resultSeed);

  let text = creative.getExtra(Creative.EXTRA_TEXT_PATTERN, "{user}");

  if (/halloween/i.test(userText)) {
    text = text.replace("{halloween}", "");
  } else {
    text = text.replace("{halloween}", "Halloween");
  }

  if (creative.hasExtra(Creative.EXTRA_TEXT_PAINTERS)) {
    const painters = creative.getExtra(Creative.EXTRA_TEXT_PAINTERS);
    text = text.replace("{painter}", painters[resultSeed % painters.length]);
  }

  const textWithoutUserText = text
    .replace("{user}", "")
    .trim()
    .replace(/\s{2,}/, " ");

  text = text
    .replace("{user}", userText)
    .trim()
    .replace(/\s{2,}/, " ");

  creative.setExtra(Creative.EXTRA_TEXT_RESULT, text);
  creative.setExtra(Creative.EXTRA_TEXT_APPEND, textWithoutUserText);

  const taskConfig = new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod("sd_v1_4", {seed: resultSeed}))
    .addPrompt(text)
    .build();

  const taskCacheKey = createMd5Token(taskConfig);

  if (!tasksCache[taskCacheKey]) {
    tasksCache[taskCacheKey] = photolabTask(
      taskConfig,
      stepConfig.getResultTimeout || 1000,
      stepConfig.getResultInterval || 1000,
    )
  }

  creative.setTaskConfig("s" + stepIndex, taskConfig);

  return tasksCache[taskCacheKey].then((taskResult) => {
    creative.setTask("s" + stepIndex, taskResult);

    if (typeof stepConfig.setAsFile === "string" && stepConfig.setAsFile.length > 0) {
      creative.setFile(stepConfig.setAsFile, taskResult.resultUrl);
    }

    return taskResult;
  }).catch((err) => {
    delete tasksCache[taskCacheKey];
    throw err;
  });
}

export function layoutStep(processing, creative, stepIndex, stepConfig) {
  const taskConfig = new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod(stepConfig.id))
    .addImage(new PhotolabTaskImageUrl(processing.layoutFile.url))
    .addImage(new PhotolabTaskImageUrl(getResultUrlFromTask(creative.getTask("s" + (stepIndex-1)))))
    .build();

  const taskCacheKey = createMd5Token(taskConfig);

  if (!tasksCache[taskCacheKey]) {
    tasksCache[taskCacheKey] = photolabTask(
      taskConfig,
      stepConfig.getResultTimeout || 1000,
      stepConfig.getResultInterval || 1000,
    )
  }

  creative.setTaskConfig("s" + stepIndex, taskConfig);

  return tasksCache[taskCacheKey].then((taskResult) => {
    creative.setTask("s" + stepIndex, taskResult);

    if (typeof stepConfig.setAsFile === "string" && stepConfig.setAsFile.length > 0) {
      creative.setFile(stepConfig.setAsFile, taskResult.resultUrl);
    }

    return taskResult;
  });
}

function apiWatermarkStep(processing, creative, stepIndex, stepConfig) {
  const imageUrl = getResultUrlFromTask(creative.getTask("s" + (stepIndex-1)));

  const watermarkConfig = {
    "content_url": assetUrl("assets/images/watermarks/default_4.png"),
    "position": "bottom-right",
    "x": "3%",
    "y": "2%",
    "percentage": 30,
  };

  return api.createTask("creative_layers", {content_url: imageUrl, layers: [watermarkConfig]})
    .then((taskResult) => waitTaskHelper(creative, "template_store", taskResult, 1000))
    .then((taskResult) => {
      creative.setTask("s" + stepIndex, taskResult);

      if (typeof stepConfig.setAsFile === "string" && stepConfig.setAsFile.length > 0) {
        creative.setFile(stepConfig.setAsFile, taskResult.result.url);
      }

      return taskResult;
    });
}

export function addWatermark(source, watermark, watermarkConfig) {
  const canvas = document.createElement("canvas");
  canvas.width = source.width;
  canvas.height = source.height;

  const canvasCtx = canvas.getContext("2d");
  canvasCtx.drawImage(source, 0, 0);

  const watermarkWidth = Math.round(canvas.width / 100 * watermarkConfig.percentage);
  const watermarkHeight = watermarkWidth * watermark.height / watermark.width;

  const watermarkPosX = canvas.width - (watermarkWidth + Math.round(watermarkConfig.x * (canvas.width / 100)));
  const watermarkPosY = canvas.height - (watermarkHeight + Math.round(watermarkConfig.y * (canvas.height / 100)));

  canvasCtx.drawImage(watermark, watermarkPosX, watermarkPosY, watermarkWidth, watermarkHeight);

  return canvas;
}

export function watermarkStep(processing, creative, stepIndex, stepConfig) {
  hitEvent(hits.FRONTEND_WATERMARK_STARTED);

  let imageUrl;
  if (stepConfig.image === "@layoutFile") {
    imageUrl = processing.layoutFile.url;
  } else {
    imageUrl = getResultUrlFromTask(creative.getTask("s" + (stepIndex-1)));
  }

  // const fileName = imageUrl.substring(imageUrl.lastIndexOf('/')+1);
  const watermarkConfig = stepConfig.watermark;

  return Promise.race([
    creativeTimeoutPromise(5000),
    Promise.all([
      promisifyImage(transformToDownloadUrl(imageUrl), true),
      promisifyImage(watermarkConfig.url, true),
    ])
  ])
  .then(([source, watermark]) => {
    const canvas = addWatermark(source, watermark, watermarkConfig);

    return new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg"));
  })
  .then((blob) => api.tempImagesUploadFile(blob, "jpeg"))
  .then((fileUrl) => {
    hitEvent(hits.FRONTEND_WATERMARK_PROCESSED);
    creative.setFile(
      stepConfig.setAsFile || "raw_watermark",
      fileUrl
    );
  })
  .catch((error) => {
    hitEvent(hits.FRONTEND_WATERMARK_FAILED);
    logEvent(userEvents.FRONTEND_WATERMARK_FAILED, {error: error.message});

    if (error instanceof CreativeTimeoutError) {
      hitEvent(hits.FRONTEND_WATERMARK_FAILED_TIMEOUT);
    }

    throw error;
  });
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export default (processing, creative) => {
  const steps = creative.getExtra(Creative.EXTRA_COMBO_STEPS);

  function waitChain(index) {
    return new Promise((resolve, reject) => {
      const step = steps[index];
      let stepHandler = null;

      switch (step.type || "template") {
        case "template": {
          stepHandler = templateStep(processing, creative, index, step);
          break;
        }
        case "sd": {
          stepHandler = sdStep(processing, creative, index, step);
          break;
        }
        case "api_watermark": {
          stepHandler = apiWatermarkStep(processing, creative, index, step);
          break;
        }
        case "watermark": {
          stepHandler = watermarkStep(processing, creative, index, step);
          break;
        }
        case "layout": {
          stepHandler = layoutStep(processing, creative, index, step);
          break;
        }
        case "text1": {
          stepHandler = _textStep(processing, creative, index, step, layoutText1);
          break;
        }
        case "text1w": {
          stepHandler = _textStep(processing, creative, index, step, layoutText1w);
          break;
        }
        case "text2": {
          stepHandler = _textStep(processing, creative, index, step, layoutText2);
          break;
        }
        case "text2w": {
          stepHandler = _textStep(processing, creative, index, step, layoutText2w);
          break;
        }
        case "text3": {
          stepHandler = _textStep(processing, creative, index, step, layoutText3);
          break;
        }
        case "text4": {
          stepHandler = _textStep(processing, creative, index, step, layoutText4);
          break;
        }
        case "text5": {
          stepHandler = _textStep(processing, creative, index, step, layoutText5);
          break;
        }
        case "text6": {
          stepHandler = _textStep(processing, creative, index, step, layoutText6);
          break;
        }
        default: {
          throw new Error(`Unrecognized combo step: '${step.type}'.`);
        }
      }

      return stepHandler
        .then((res) => steps[index + 1] ? waitChain(index + 1) : res)
        .then(resolve)
        .catch(reject);
    });
  }

  const timeoutMs = creative.getExtra(
    Creative.EXTRA_PROCESSING_TIMEOUT,
    window.appConfig.processings.creativeTimeout
  );

  return new Promise((resolve, reject) => {
    // todo остановить waitChain
    Promise.race([
      creativeTimeoutPromise(timeoutMs),
      waitChain(0),
    ])
    .then(() => {
      const file = creative.getFile("raw_watermark") || creative.getFile("raw");

      return promisifyImage(file)
        .then(() => creative.markAsProcessed(file));
    })
    .then(defaultHandlerResolver(creative, resolve))
    .catch(defaultHandlerCatch(creative, reject));
  });
}

function _textStep(processing, creative, stepIndex, stepConfig, layoutMethod) {
  const imageUrl = transformToDownloadUrl(getResultUrlFromTask(creative.getTask("s" + (stepIndex-1))));

  return Promise.race([
    creativeTimeoutPromise(5000),
    imageToCanvas(imageUrl, true)
  ])
    .then((canvas) => layoutMethod(canvas, processing.getExtra(Processing.EXTRA_TEXT)))
    .then((canvas) => new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg")))
    .then((blob) => api.tempImagesUploadFile(blob, "jpeg"))
    .then((fileUrl) => {
      hitEvent(hits.FRONTEND_WATERMARK_PROCESSED);
      creative.setFile(
        stepConfig.setAsFile || "raw_watermark",
        fileUrl
      );
    })
    .catch((error) => {
      hitEvent(hits.FRONTEND_WATERMARK_FAILED);
      logEvent(userEvents.FRONTEND_WATERMARK_FAILED, {error: error.message});

      if (error instanceof CreativeTimeoutError) {
        hitEvent(hits.FRONTEND_WATERMARK_FAILED_TIMEOUT);
      }

      throw error;
    });
}

function layoutText1(canvas, text) {
  return loadFonts([
    fontsLibrary.MyriadProSemibold,
    fontsLibrary.PoppinsBold,
  ]).then(() => {
    const textBounds = {
      x: 180,
      y: 1480,
      width: 720,
      height: 120,
    };

    const ctx = canvas.getContext("2d");

    drawDebugRect(ctx, textBounds, 5, "red");

    const spans = textToSpans(`“${text}”`);
    spans.forEach((span) => {
      span.textWeight = 600;
      span.fontName = fontsLibrary.MyriadProSemibold.name;
    });

    const measuredLinesData = textSpansToLines(canvas, spans, {
      width: textBounds.width,
      height: textBounds.height,
      fontSize: 64,
      lineHeight: 1.5,
    });

    measuredLinesData.lines.forEach((line) => line.textAlign = "center");

    let {y} = drawTextLines(ctx, measuredLinesData, textBounds);

    const fontSize = measuredLinesData.lines.first().spans.first().fontSize;

    y += 30;
    ctx.save();
    ctx.font = `normal 600 ${fontSize}px '${fontsLibrary.MyriadProSemibold.name}'`;
    ctx.fillStyle = "#000000";
    ctx.textBaseline = "top";
    ctx.textAlign = "center";
    ctx.fillText(textSign, parseInt(textBounds.x + textBounds.width/2), y);
    y += ctx.measureText("O").width;
    ctx.restore();

    y += 30;
    ctx.save();
    ctx.font = `normal bold 75px '${fontsLibrary.PoppinsBold.name}'`;
    ctx.fillStyle = "#F8C122";
    ctx.textBaseline = "top";
    ctx.textAlign = "center";
    ctx.fillText("ArtBot.ai", parseInt(canvas.width / 2), y + 30);
    ctx.restore();

    return canvas;
  });
}

function layoutText1w(canvas, text) {
  return loadFonts([
    fontsLibrary.MyriadProSemibold,
    fontsLibrary.PoppinsBold,
  ]).then(() => {
    const textBounds = {
      x: 180,
      y: 1280,
      width: 720,
      height: 320,
    };

    const ctx = canvas.getContext("2d");

    drawDebugRect(ctx, textBounds, 5, "red");

    const spans = textToSpans(`“${text}”`);
    spans.forEach((span) => {
      span.textWeight = 600;
      span.fontName = fontsLibrary.MyriadProSemibold.name;
    });

    const measuredLinesData = textSpansToLines(canvas, spans, {
      width: textBounds.width,
      height: textBounds.height,
      fontSize: 64,
      lineHeight: 1.5,
    });

    measuredLinesData.lines.forEach((line) => line.textAlign = "center");

    let {y} = drawTextLines(ctx, measuredLinesData, textBounds);

    const fontSize = measuredLinesData.lines.first().spans.first().fontSize;

    y += 30;
    ctx.save();
    ctx.font = `normal 600 ${fontSize}px '${fontsLibrary.MyriadProSemibold.name}'`;
    ctx.fillStyle = "#000000";
    ctx.textBaseline = "top";
    ctx.textAlign = "center";
    ctx.fillText(textSign, parseInt(textBounds.x + textBounds.width/2), y);
    y += ctx.measureText("O").width;
    ctx.restore();

    ctx.save();
    ctx.font = `normal bold 75px '${fontsLibrary.PoppinsBold.name}'`;
    ctx.fillStyle = "#F8C122";
    ctx.textBaseline = "top";
    ctx.textAlign = "center";
    ctx.fillText("ArtBot.ai", parseInt(canvas.width / 2), 1700);
    ctx.restore();

    return canvas;
  });
}

function layoutText2(canvas, text) {
  return loadFonts([
    fontsLibrary.MyriadProSemibold,
    fontsLibrary.PoppinsBold,
  ]).then(() => {
    _layoutText2(canvas, text, 28, {
      x: 350,
      y: 960,
      width: 500,
      height: 60,
    });

    return canvas;
  });
}

function layoutText2w(canvas, text) {
  return loadFonts([
    fontsLibrary.MyriadProSemibold,
    fontsLibrary.PoppinsBold,
  ]).then(() => {
    _layoutText2(canvas, text, 44, {
      x: 95,
      y: 960,
      width: 700,
      height: 60,
    });

    return canvas;
  });
}

function _layoutText2(canvas, text, textMaxLength, textBounds) {
  const ctx = canvas.getContext("2d");

  drawDebugRect(ctx, textBounds, 5, "red");

  if (text.length > textMaxLength) {
    text = text.slice(0, textMaxLength) + "...";
  }

  ctx.save();
  ctx.fillStyle = "#000000";
  ctx.font = "normal 600 30px 'Myriad Pro'";
  ctx.textAlign = "left";
  ctx.textBaseline = "top";
  const textWidth = ctx.measureText(text).width;
  ctx.fillText(text, textBounds.x, textBounds.y);
  ctx.font = "normal 600 54px 'Myriad Pro'";
  const quoteSize = ctx.measureText("“").width;
  ctx.fillText("“", (textBounds.x - quoteSize) - 10, textBounds.y - 10);
  ctx.fillText("”", (textBounds.x + textWidth) + 10, textBounds.y - 10);
  ctx.restore();

  // const spans = textToSpans(`“${text}”`);
  // spans.forEach((span) => {
  //   span.textWeight = 600;
  //   span.fontName = fontsLibrary.MyriadProSemibold.name;
  // });
  //
  // const measuredLinesData = textSpansToLines(canvas, spans, {
  //   width: textBounds.width,
  //   height: textBounds.height,
  //   fontSize: 64,
  //   lineHeight: 1.5,
  // });
  //
  // let {y} = drawTextLines(ctx, measuredLinesData, textBounds);
  //
  // const fontSize = measuredLinesData.lines.first().spans.first().fontSize;

  ctx.save();
  ctx.font = "normal 600 25px 'Myriad Pro'";
  ctx.fillStyle = "#000000";
  ctx.textBaseline = "top";
  ctx.textAlign = "start";
  ctx.fillText(textSign, textBounds.x, textBounds.y + 45);
  ctx.restore();

  ctx.save();
  ctx.font = `normal bold 46px '${fontsLibrary.PoppinsBold.name}'`;
  ctx.fillStyle = "#F8C122";
  ctx.textBaseline = "top";
  ctx.textAlign = "right";
  ctx.fillText("ArtBot.ai", 1018, 1000);
  ctx.restore();
}

function layoutText3(canvas, text) {
  return loadFonts([
    fontsLibrary.MyriadProRegular,
  ]).then(() => {
    const spans = textToSpans("ArtBot.ai, my Halloween Art request is: \"" + text + "\"");

    spans.forEach((span, index) => {
      if (index === 0) {
        span.textColor = "#999999";
      }

      span.textWeight = 400;
      span.fontName = fontsLibrary.MyriadProRegular.name;
    });

    const ctx = canvas.getContext("2d");
    const textBounds = {
      x: 312,
      y: 275,
      width: 660,
      height: 140,
    };

    drawDebugRect(ctx, textBounds, 5, "red");

    const measuredLinesData = textSpansToLines(canvas, spans, {
      width: textBounds.width,
      height: textBounds.height,
      fontSize: 56,
      lineHeight: 1.5,
    });

    drawTextLines(ctx, measuredLinesData, textBounds);

    return canvas;
  });
}

function _bodyText1(canvas, text, textBounds, fontName, textColor) {
  const spans = textToSpans(text);
  spans.forEach((span) => {
    span.textColor = textColor;
    span.fontName = fontName;
  });

  const ctx = canvas.getContext("2d");

  drawDebugRect(ctx, textBounds, 5, "red");

  const measuredLinesData = textSpansToLines(canvas, spans, {
    width: textBounds.width,
    height: textBounds.height,
    fontSize: 72,
    lineHeight: 1.5,
  });

  measuredLinesData.lines.forEach((line) => line.textAlign = "center")

  drawTextLines(ctx, measuredLinesData, textBounds, {
    verticalAlign: "middle",
  });
}

function layoutText4(canvas, text) {
  return loadFonts([
    fontsLibrary.AvenirNextCyrDemi,
  ]).then(() => {
    const textBounds = {
      x: 545,
      y: 1335,
      width: 365,
      height: 160,
    };

    _bodyText1(canvas, text, textBounds, fontsLibrary.AvenirNextCyrDemi.name, "#000000");

    return canvas;
  });
}

function layoutText5(canvas, text) {
  return loadFonts([
    fontsLibrary.MyriadProSemibold,
  ]).then(() => {
    const textBounds = {
      x: 580,
      y: 1400,
      width: 410,
      height: 235,
    };

    _bodyText1(canvas, text, textBounds, fontsLibrary.MyriadProSemibold.name, "#ffffff");

    return canvas;
  });
}

function layoutText6(canvas, text) {
  return loadFonts([
    fontsLibrary.PoppinsBold,
  ]).then(() => {
    const ctx = canvas.getContext("2d");
    const textMaxLength = 32;
    const textRect = {
      x: 345,
      y: 982,
      width: 650,
      height: 51,
    };

    if (text.length > textMaxLength) {
      text = text.slice(0, textMaxLength) + "...";
    }

    text = "“" + text + "”";

    ctx.save();
    ctx.font = `normal bold 30px '${fontsLibrary.PoppinsBold.name}'`;
    ctx.textBaseline = "middle";
    ctx.textAlign = "left";
    ctx.fillStyle = "#FBC429";
    const boxWidth = ctx.measureText(text).width + 40;
    ctx.fillRect(textRect.x, textRect.y, boxWidth, textRect.height);
    ctx.fillStyle = "#000000";
    ctx.fillText(text, textRect.x + 20, parseInt(textRect.y + textRect.height/2) + 2);
    ctx.restore();

    ctx.save();
    ctx.font = `normal bold 30px '${fontsLibrary.PoppinsBold.name}'`;
    ctx.fillStyle = "#FBC429";
    ctx.textBaseline = "bottom";
    ctx.textAlign = "left";
    ctx.strokeWidth = 2;
    ctx.strokeStyle = "#000000";
    ctx.strokeText("ArtBot.ai", textRect.x + 20, textRect.y - 8);
    ctx.fillText("ArtBot.ai", textRect.x + 20, textRect.y - 8);
    ctx.restore();

    return canvas;
  });
}

function loadFonts(fontsConfig) {
  return Promise.all(fontsConfig.map((font) => {
    const fontFace = new FontFace(font.name, font.url, {
      style: font.style,
      weight: font.weight,
    });

    return fontFace.load().then((f) => document.fonts.add(f));
  }));
}