import React from "react";
import PropTypes from "prop-types";
import DumbImageView from "../../components/DumbImageView";
import CreativeErrorView from "./CreativeErrorView";
import Creative from "../../photolab/Creative";
import i18n from "../../i18n";
import {logEvent, userEvents} from "../../utils/log";
import clientSessionStorage from "../../utils/client-session-storage";
import Processing from "../../photolab/Processing";
import {addWatermark} from "../../photolab/handlers/combo.handler";
import {promisifyImage} from "../../photolab/handlers/helpers";
import {frontendWatermarkPreviewConfig} from "../../photolab/config/helpers";
import {creativeName} from "../../utils/creative";
import processingManager from "../../photolab/ProcessingManager";
import clientStorage from "../../utils/client-storage";
import CreativeLoaderView from "./CreativeLoaderView";
import AppContext from "../../contexts/AppContext";
import groups from "../../photolab/config/groups";

export default class CreativeView extends React.Component {

  state = {
    watermarkImage: null,
    creativeImage: null,
    watermarkContainerPosition: {},
    uploadFileTooltipIsHidden: true,
  };

  constructor(props) {
    super(props);

    this.creativeContainerRef = React.createRef();
    this.checkWatermarkPositionTimer = null;
  }

  componentDidMount() {
    const step = processingManager.processing.getExtra(Processing.EXTRA_STEP);

    let uploadFileTooltipIsHidden = true;

    if (step === Processing.STEP_RESULT_WITHOUT_FILE) {
      uploadFileTooltipIsHidden = false;
    } else if (step === Processing.STEP_RESULT) {
      uploadFileTooltipIsHidden = clientStorage.getUploadFileTooltipIsHidden();

      if (!uploadFileTooltipIsHidden) {
        setTimeout(this.handleHideUploadFileTooltip, 5000);
      }
    }

    this.setState({uploadFileTooltipIsHidden});
  }

  componentWillUnmount() {
    clearInterval(this.checkWatermarkPositionTimer);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.fakeWatermark !== this.props.fakeWatermark) {
      this.setWatermarkImage();
    }
  }

  handleHideUploadFileTooltip = (e) => {
    if (this.state.uploadFileTooltipIsHidden
      || processingManager.processing.getExtra(Processing.EXTRA_STEP) === Processing.STEP_RESULT_WITHOUT_FILE)
    {
      return;
    }

    clientStorage.setUploadFileTooltipIsHidden(true);

    this.setState({
      uploadFileTooltipIsHidden: true,
    });
  }

  handleStartFileChoosing = () => {
    this.handleHideUploadFileTooltip();
    this.props.showUploadSelfieModal();
  }

  checkWatermarkPosition = () => {
    if (!this.state.creativeImage || !this.creativeContainerRef || !this.creativeContainerRef.current) {
      return;
    }

    const rectContainer = this.creativeContainerRef.current.getBoundingClientRect();

    const scale = Math.min(
      rectContainer.width / this.state.creativeImage.naturalWidth,
      rectContainer.height / this.state.creativeImage.naturalHeight
    );

    const imageWidth = this.state.creativeImage.naturalWidth * scale;
    const imageHeight = this.state.creativeImage.naturalHeight * scale;

    this.setState({
      watermarkContainerPosition: {
        right: ((rectContainer.width - imageWidth + 18) / 2) + "px",
        bottom: ((rectContainer.height - imageHeight + 118) / 2) + "px",
      }
    });

    if (!window.hideProWatermarkTooltipTimeoutId
      && !clientSessionStorage.getProWatermarkTooltipIsHidden())
    {
      window.hideProWatermarkTooltipTimeoutId = setTimeout(() => {
        clearTimeout(window.hideProWatermarkTooltipTimeoutId);
        clientSessionStorage.setProWatermarkTooltipIsHidden(true);
      }, 5000);
    }
  }

  setWatermarkImage = () => {
    if (!this.state.creativeImage || !this.props.fakeWatermark) {
      this.setState({
        watermarkImage: null
      });

      return;
    }

    const image = this.state.creativeImage;
    const cacheKey = `watermark_image:${image.width}x${image.height}`;

    if (window.appGlobalCache[cacheKey]) {
      this.setState({
        watermarkImage: window.appGlobalCache[cacheKey],
      });

      return;
    }

    promisifyImage(frontendWatermarkPreviewConfig.url, true)
      .then((watermark) => {
        const dummyImage = new Image();
        dummyImage.width = image.width;
        dummyImage.height = image.height;

        const canvas = addWatermark(dummyImage, watermark, frontendWatermarkPreviewConfig);
        const watermarkImage = canvas.toDataURL();

        this.setState({watermarkImage}, () => {
          window.appGlobalCache[cacheKey] = watermarkImage;
        });
      })
      .catch((error) => {

      });
  }

  handleImageLoaded = (image) => {
    const creative = this.props.creative;
    const processing = processingManager.processing;

    clearInterval(this.checkWatermarkPositionTimer);
    this.checkWatermarkPositionTimer = setInterval(this.checkWatermarkPosition, 1000/6);

    this.setState({
      creativeImage: image,
    }, this.setWatermarkImage);

    const positionInGroup = processing
      .getCreativesInGroup(creative.group)
      .findIndex(c => c.id === creative.id);

    logEvent(userEvents.CREATIVE_VIEW, {
      group: creative.group,
      gender: processing.getGender(),
      template_id: creativeName(creative),
      alias: creative.getExtra(Creative.EXTRA_ALIAS, ""),
      position: processing.groups.indexOf(creative.group) + 1,
      position_in_group: positionInGroup + 1,
      is_refresh: creative.isRefreshed,
      is_new_client: window.clientConfig.isNew,
      text: processing.getExtra(Processing.EXTRA_TEXT, ""),
      text_result: creative.getExtra(Creative.EXTRA_TEXT_RESULT, processing.getExtra(Creative.EXTRA_TEXT_RESULT, "")),
      text_append: creative.getExtra(Creative.EXTRA_TEXT_APPEND, processing.getExtra(Creative.EXTRA_TEXT_APPEND, "")),
      ai_seed: creative.getExtra(Creative.EXTRA_AI_SEED, processing.getExtra(Creative.EXTRA_AI_SEED, -1)),
      ai_group: processing.getExtra(Processing.EXTRA_AI_GROUP, ""),
    });

    this.props.onImageLoaded();
  };

  handleClick = (e) => {
    const processing = processingManager.processing;

    const templateId = this.props.creative.group
      + "/"
      + creativeName(this.props.creative);

    const text = this.props.creative.getExtra(
      Creative.EXTRA_TEXT_RESULT,
      processing.getExtra(Creative.EXTRA_TEXT_RESULT)
    );

    const seed = this.props.creative.getExtra(Creative.EXTRA_AI_SEED)
      || processing.getExtra(Creative.EXTRA_AI_SEED);

    if (window.appConfig.isTestInstance) {
      this.context.showToast({
        message: `TEMPLATE: ${templateId}<br />SEED: ${seed}<br />TEXT: ${text}`,
        delay: 5000,
      });
    }

    console.info("TEMPLATE", `${templateId}, seed: ${seed}, text: ${text}`);

    this.props.onClick && this.props.onClick(e);
  };

  render() {
    const creative = this.props.creative;
    const processing = processingManager.processing;

    const creativeIsProcessed = creative && creative.isProcessed;
    const creativeIsFailed = creative && creative.isFailed;
    const creativeIsPending = (creative && creative.isPending)
      || (creative && creative.isProcessed && !this.state.creativeImage);

    const watermarkContainerIsVisible = window.clientConfig.isPro
      && creativeIsProcessed
      && this.state.creativeImage
      && !processingManager.processing.getExtra(Processing.EXTRA_PRO_WATERMARK_SHOULD_BE_REMOVED, false)
      && Object.keys(this.state.watermarkContainerPosition).length > 0;

    const processingStep = processing.getExtra(Processing.EXTRA_STEP);
    const tooltipText = processingStep === Processing.STEP_RESULT_WITHOUT_FILE
      ? "tooltip_change_selfie_without_file"
      : "tooltip_change_selfie";

    const uploadSelfieIsHidden = (creative && creative.group === groups.RESULT_WITH_WATERMARK)
      || processingStep === Processing.STEP_LAYOUT;

    return <div className="creative-holder" onClick={this.handleClick}>
      {creativeIsProcessed && <div className="creative" ref={this.creativeContainerRef}>
        <DumbImageView
          image={creative.getFile("raw")}
          onImageLoaded={this.handleImageLoaded} />

        {this.state.watermarkImage && <img
          className="watermark-image"
          src={this.state.watermarkImage}
          alt="."
        />}

        {watermarkContainerIsVisible && <div style={this.state.watermarkContainerPosition} className="watermark-container">
          <div>
            <div className="tooltip" hidden={clientSessionStorage.getProWatermarkTooltipIsHidden()}>
              <p dangerouslySetInnerHTML={{__html: i18n.t("toggle_watermark_tooltip__text")}} />
            </div>
            <div className="btn-remove-logo" onClick={this.props.onHideWatermarkButtonClick} />
          </div>
        </div>}

        {!uploadSelfieIsHidden && <div className="mask-container" onClick={this.handleStartFileChoosing}>
          <button
            className="btn-change-mask"
            onClick={this.handleStartFileChoosing}>
            <SvgUploadIcon />
          </button>
          <div className="tooltip" hidden={this.state.uploadFileTooltipIsHidden}>
            <span>{i18n.t(tooltipText)}</span>
          </div>
        </div>}

      </div>}

      {creativeIsPending && <CreativeLoaderView {...this.props} />}
      {creativeIsFailed && <CreativeErrorView {...this.props} />}
    </div>;
  }
}

CreativeView.contextType = AppContext;

CreativeView.propTypes = {
  creative: PropTypes.object.isRequired,
};

function SvgUploadIcon() {
  return <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20.412 11.428h-.761V2.182A2.182 2.182 0 0 0 17.469 0H9.003a2.182 2.182 0 0 0-2.181 2.182v9.246H6.06a5.6 5.6 0 0 0-5.593 5.593 5.6 5.6 0 0 0 5.593 5.593h4.801v1.725a.659.659 0 0 0 .661.661.658.658 0 0 0 .468-.194l2.876-2.876a.657.657 0 0 0 0-.935l-2.876-2.877a.657.657 0 0 0-.72-.143.66.66 0 0 0-.409.61v1.726H6.061a3.293 3.293 0 0 1-3.29-3.29 3.293 3.293 0 0 1 3.29-3.29h.76v4.64c0 .278.053.544.148.788H9.71v-.573a1.807 1.807 0 0 1 1.813-1.813c.484 0 .94.189 1.282.531l.04.04 2.836 2.837c.114.113.21.238.287.372h1.501a2.182 2.182 0 0 0 2.182-2.181v-4.64h.76a3.293 3.293 0 0 1 3.29 3.29 3.293 3.293 0 0 1-3.29 3.289c-.634 0-1.151.517-1.151 1.151 0 .636.517 1.152 1.152 1.152a5.6 5.6 0 0 0 5.593-5.593 5.6 5.6 0 0 0-5.593-5.593zm-3.714-9.416a.468.468 0 1 1 0 .936.468.468 0 0 1 0-.936zM11.21 2.1h4.05v.765h-4.05V2.1zm6.925 14.602h-9.8V3.851h9.8v12.851z" fill="#302C40"/>
  <path d="M14.356 12.364a.55.55 0 1 0 0-1.1.55.55 0 0 0 0 1.1zM11.945 12.364a.55.55 0 1 0 0-1.1.55.55 0 0 0 0 1.1zM12.152 13.555a.207.207 0 0 0-.028.26c.183.282.577.457 1.027.457.45 0 .844-.175 1.027-.457a.207.207 0 0 0-.29-.284c-.174.12-.443.188-.737.188-.293 0-.563-.069-.739-.188a.207.207 0 0 0-.26.024z" fill="#302C40"/>
  <path d="M11.654 15.23c-.527.22-.991.562-1.358.99h5.88a3.644 3.644 0 0 0-1.447-1.025 3.735 3.735 0 0 0 2.154-3.38 3.735 3.735 0 0 0-3.731-3.732c-.849 0-1.632.286-2.26.765-.361-.114-.573-.445-.573-.445.04.283.18.492.336.642-.03.026-.058.054-.086.08a1.213 1.213 0 0 1-.595-.31c.086.237.236.402.396.517-.59.66-.95 1.53-.95 2.482 0 1.525.92 2.838 2.234 3.416zm-.894-5.402c.404.372 1.323.631 2.392.631 1.07 0 1.988-.26 2.392-.631.448.539.719 1.232.719 1.986a3.114 3.114 0 0 1-3.111 3.111 3.114 3.114 0 0 1-3.111-3.11c0-.755.27-1.448.719-1.987z" fill="#302C40"/>
</svg>;
}