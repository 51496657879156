/* eslint-disable no-unused-vars */

import Creative from "../Creative";
import groups from "./groups";
import {getCreativesInRandomOrder} from "./helpers";
import CreativeConfig from "../CreativeConfig";
import {handlersNames} from "../handlers";
import clientStorage from "../../utils/client-storage";
import Processing from "../Processing";
import {assetUrl} from "../../utils/etc";
import {isTokenSymbol} from "../../utils/config.utils";

const seeds = clientStorage.getArtSeeds(20, 1, 500);

const phrasesPatterns = [
  "{user} christmas winter impressionism art",
  "{user} christmas aquarelle painting with santa",
  "{user} christmas postcard impressionism art",
  "{user} christmas retro postcard with ornaments",
  "{user} Christmas watercolour illustration",
  "{user} snow xmas fairy tale oil painting illustration",
  "{user} christmas and happy new year oil painting with snowflakes and Christmas tree as Art Dali",
  "{user} christmas and happy new year oil painting with snowflakes and Christmas tree as van gogh styles",
  "{user} with a snowflakes as christmas tree illustration watercolor painting art Dona Gelsinger style",
  "{user} as snow Christmas vintage oil painting with a Christmas tree toys",
  "{user} xmas history art painting",
  "{user} christmas illustration",
  "{user} oil painting Christmas and happy new year",
]

function getLayoutGroups() {
  return phrasesPatterns.map((_, index) => `phrase_${index}`);
}

function getResultGroups() {
  let list = [
    groups.RESULT_STORY,
    groups.RESULT_SQUARE,
    groups.RESULT_ATTACH_2SELFIES,
    groups.RESULT_7489,
  ].shuffle();

  list.unshift(
    groups.RESULT_BODIES,
    groups.RESULT_WITH_WATERMARK
  );

  return list;
}

function getResultWithoutFileGroups() {
  let list = [
    groups.RESULT_WITHOUT_FILE_STORY,
    groups.RESULT_WITHOUT_FILE_SQUARE,
    groups.RESULT_WITHOUT_FILE_ATTACH_2SELFIES,
    groups.RESULT_WITHOUT_FILE_7489,
  ].shuffle();

  list.unshift(
    groups.RESULT_WITHOUT_FILE_BODIES,
    groups.RESULT_WITH_WATERMARK
  );

  return list;
}

function getCommonCreatives() {
  return [
    // new CreativeConfig(groups.COMMON, "gender", handlersNames.GENDER),
  ];
}

function getLayoutTab1Creatives() {
  const seed = seeds[0];

  return [
    new CreativeConfig(groups.LAYOUT_TAB_1, "sd_v1_4", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_AI_SEED, seed)
      .setExtra(Creative.EXTRA_TEXT_PATTERN, phrasesPatterns[0])
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {type: "sd", seed, setAsFile: "raw"},
      ]),
  ];
}

function getLayoutTab2Creatives() {
  const seed = seeds[1];

  return [
    new CreativeConfig(groups.LAYOUT_TAB_2, "sd_v1_4", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_AI_SEED, seed)
      .setExtra(Creative.EXTRA_TEXT_PATTERN, phrasesPatterns[1])
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {type: "sd", seed, setAsFile: "raw"}
      ]),
  ];
}

function getLayoutTab3Creatives() {
  const seed = seeds[2];

  return [
    new CreativeConfig(groups.LAYOUT_TAB_3, "sd_v1_4", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_AI_SEED, seed)
      .setExtra(Creative.EXTRA_TEXT_PATTERN, phrasesPatterns[2])
      // .setExtra(Creative.EXTRA_TEXT_PAINTERS, ["van gogh", "gauguin", "rembrandt", "degas"])
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {type: "sd", seed, setAsFile: "raw"}
      ]),
  ];
}

function getLayoutTab4Creatives() {
  const seed = seeds[3];

  return [
    new CreativeConfig(groups.LAYOUT_TAB_4, "sd_v1_4", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_AI_SEED, seed)
      .setExtra(Creative.EXTRA_TEXT_PATTERN, phrasesPatterns[3])
      // .setExtra(Creative.EXTRA_TEXT_PAINTERS, ["rubens", "gauguin"])
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {type: "sd", seed, setAsFile: "raw"}
      ]),
  ];
}

function getResultWithWatermarkCreatives() {
  return [
    new CreativeConfig(groups.RESULT_WITH_WATERMARK, "art", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {
          type: "watermark",
          image: "@layoutFile",
          watermark: {
            url: assetUrl("assets/watermarks/1.png"),
            x: 0,
            y: 0,
            percentage: 33,
          },
          setAsFile: "raw",
        },
      ]),
  ];
}

function getResultStoryCreatives() {
  return [
    new CreativeConfig(groups.RESULT_STORY, "7436", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: 6756},
        {
          id: 7436,
          images: [
            {src: "#"},
            {src: "@layoutFile"},
          ],
        },
        {type: "text1", setAsFile: "raw"},
      ]),
  ];
}

function getResultSquareCreatives() {
  return [
    new CreativeConfig(groups.RESULT_SQUARE, "7437", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: 6756},
        {
          id: 7437,
          images: [
            {src: "#"},
            {src: "@layoutFile"},
          ],
        },
        {type: "text2", setAsFile: "raw"},
      ]),
  ];
}

function getResultAttach2SelfiesCreatives() {
  return [
    new CreativeConfig(groups.RESULT_ATTACH_2SELFIES, "7414", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_ATTACH_FILE, "pending")
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: 6756, images: [{src: "@selfieMessageFile"}]}, // quick fix
        {id: 6756, images: [{src: "@"}]},
        {
          id: 7414,
          images: [
            {src: "#1"},
            {src: "@layoutFile"}, // quick fix
            {src: "#0"},
          ],
        },
        {type: "text3", setAsFile: "raw"},
      ]),
  ];
}

function getResult7489Creatives() {
  return [
    new CreativeConfig(groups.RESULT_7489, "7489", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: 6756, images: [{src: "@"}]},
        {
          id: 7489,
          images: [
            {src: "#"},
            {src: "@layoutFile"}
          ]
        },
        {type: "text6", setAsFile: "raw"},
      ]),
  ];
}

function getResultBodiesCreatives() {
  const bodies = [
    7451, 7453, 7457, 7440, 7441, 7444, 7445, 7452,
  ];

  const bodyId = bodies.shuffle().first();

  return [
    new CreativeConfig(groups.RESULT_BODIES, bodyId, handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: 6756},
        {id: bodyId, images: [{src: "#"}, {src: "@layoutFile"}]},
        {
          type: bodyId === 7438 ? "text5" : "text4",
          setAsFile: "raw",
        },
      ]),
  ]
}

function getResultWithoutFileStoryCreatives() {
  return [
    new CreativeConfig(groups.RESULT_WITHOUT_FILE_STORY, "7448", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: 7448, images: [{src: "@layoutFile"}]},
        {type: "text1w", setAsFile: "raw"},
      ]),
  ];
}

function getResultWithoutFileSquareCreatives() {
  return [
    new CreativeConfig(groups.RESULT_WITHOUT_FILE_SQUARE, "7447", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: 7447, images: [{src: "@layoutFile"}]},
        {type: "text2w", setAsFile: "raw"},
      ]),
  ];
}

function getResultWithoutFileAttach2SelfiesCreatives() {
  return [
    new CreativeConfig(groups.RESULT_WITHOUT_FILE_ATTACH_2SELFIES, "attach", handlersNames.DUMMY)
      .setExtra(Creative.EXTRA_DUMMY, true),
  ];
}

function getResultWithoutFileBodiesCreatives() {
  return [
    new CreativeConfig(groups.RESULT_WITHOUT_FILE_BODIES, "body", handlersNames.DUMMY)
      .setExtra(Creative.EXTRA_DUMMY, true),
  ];
}

function getResultWithoutFile7489Creatives() {
  return [
    new CreativeConfig(groups.RESULT_WITHOUT_FILE_7489, "7489", handlersNames.DUMMY)
      .setExtra(Creative.EXTRA_DUMMY, true),
  ];
}

export default {
  getGroups: function(step) {
    switch (step) {
      case Processing.STEP_LAYOUT: {
        return getLayoutGroups();
      }
      case Processing.STEP_RESULT: {
        return getResultGroups();
      }
      case Processing.STEP_RESULT_WITHOUT_FILE: {
        return getResultWithoutFileGroups();
      }
      default: {
        throw new Error(`Unrecognized processing step: '${step}'.`);
      }
    }
  },

  getCreatives: function() {
    let listOfAll = [
      // ...getLayoutTab1Creatives(),
      // ...getLayoutTab2Creatives(),
      // ...getLayoutTab3Creatives(),
      // ...getLayoutTab4Creatives(),
      ...getResultWithWatermarkCreatives(),
      ...getResultStoryCreatives(),
      ...getResultSquareCreatives(),
      ...getResultBodiesCreatives(),
      ...getResult7489Creatives(),
      ...getResultAttach2SelfiesCreatives(),
      ...getResultWithoutFileStoryCreatives(),
      ...getResultWithoutFileSquareCreatives(),
      ...getResultWithoutFileAttach2SelfiesCreatives(),
      ...getResultWithoutFileBodiesCreatives(),
      ...getResultWithoutFile7489Creatives(),
    ];

    phrasesPatterns.forEach((phrase, index) => {
      const seed = parseInt(Math.random() * 500);
      listOfAll.push(
        new CreativeConfig(`phrase_${index}`, "sd_v1_4", handlersNames.COMBO)
          .setExtra(Creative.EXTRA_PREVIEW_URL, null)
          .setExtra(Creative.EXTRA_AI_SEED, seed)
          .setExtra(Creative.EXTRA_TEXT_PATTERN, phrase)
          .setExtra(Creative.EXTRA_COMBO_STEPS, [
            {type: "sd", seed, setAsFile: "raw"},
          ])
      );
    });

    if (window.clientConfig.features.tabsWithRandomOrder) {
      listOfAll = [
        ...getCreativesInRandomOrder(getLayoutTab1Creatives()),
        ...getCreativesInRandomOrder(getLayoutTab2Creatives()),
        ...getCreativesInRandomOrder(getLayoutTab3Creatives()),
        ...getCreativesInRandomOrder(getLayoutTab4Creatives()),
        ...getCreativesInRandomOrder(getResultWithWatermarkCreatives()),
        ...getCreativesInRandomOrder(getResultStoryCreatives()),
        ...getCreativesInRandomOrder(getResultSquareCreatives()),
        ...getCreativesInRandomOrder(getResultBodiesCreatives()),
        ...getCreativesInRandomOrder(getResult7489Creatives()),
        ...getCreativesInRandomOrder(getResultAttach2SelfiesCreatives()),
        ...getCreativesInRandomOrder(getResultWithoutFileStoryCreatives()),
        ...getCreativesInRandomOrder(getResultWithoutFileSquareCreatives()),
        ...getCreativesInRandomOrder(getResultWithoutFileAttach2SelfiesCreatives()),
        ...getCreativesInRandomOrder(getResultWithoutFileBodiesCreatives()),
        ...getCreativesInRandomOrder(getResultWithoutFile7489Creatives()),
      ];
    }

    return [
      ...getCommonCreatives(),
      ...listOfAll,
    ];
  },
};
