export default {
  COMMON: "common",
  LAYOUT_TAB_1: "layout_tab_1",
  LAYOUT_TAB_2: "layout_tab_2",
  LAYOUT_TAB_3: "layout_tab_3",
  LAYOUT_TAB_4: "layout_tab_4",
  RESULT_WITH_WATERMARK: "result_with_watermark",
  RESULT_STORY: "result_story",
  RESULT_SQUARE: "result_square",
  RESULT_ATTACH_2SELFIES: "result_attach_2selfies",
  RESULT_BODIES: "result_bodies",
  RESULT_7489: "result_7489",
  RESULT_WITHOUT_FILE_STORY: "result_without_file_story",
  RESULT_WITHOUT_FILE_SQUARE: "result_without_file_square",
  RESULT_WITHOUT_FILE_ATTACH_2SELFIES: "result_without_file_attach_2selfies",
  RESULT_WITHOUT_FILE_BODIES: "result_without_file_bodies",
  RESULT_WITHOUT_FILE_7489: "result_without_file_7489",
};
